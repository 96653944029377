<template>
    <div>
        <div align="center" justify="center" class="pa-3">
            <v-card align="left" justify="left" class="mb-3">
                <v-card-text class="pa-3 font-weight-light white black--text">
                    <v-icon class="primary--text lighten-2 pa-2">
                        mdi-application-edit-outline </v-icon
                    >Agrupadores > Gerenciar Agrupadores do sistema
                </v-card-text>
            </v-card>
            <v-card @keyup.native.enter="aplicarFiltros" class="pa-3 mb-4">
                <v-row
                    class="pa-3"
                    :class="
                        isExtraSmall
                            ? 'd-flex justify-center'
                            : 'd-flex align-center justify-space-between'
                    "
                >
                    <v-btn
                        color="green white--text"
                        @click="criarAgrupador"
                        data-cy="criarAgrupador"
                    >
                        Criar novo agrupador
                        <v-icon class="ml-2">add</v-icon>
                    </v-btn>
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-bind="attrs"
                                v-on="on"
                                x-small
                                elevation="0"
                                fab
                                :loading="loadingInicial"
                                class="ma-2 pa-2 white--text"
                                :style="'background-color: ' + $vuetify.theme.themes.light.primary"
                                @click="checarAgrupadores()"
                            >
                                <v-icon> mdi-sync </v-icon>
                            </v-btn>
                        </template>
                        <span>Ressincronizar agrupadores</span>
                    </v-tooltip>
                </v-row>
            </v-card>
            <v-data-table
                :headers="colunasAgrupadores"
                :items="agrupadores"
                :loading="loadingInicial"
                loading-text="Carregando Agrupadores..."
                item-key="agrupadores"
                class="elevation-1 pa-3"
                :class="isExtraSmall ? 'alinhar-botao-editar' : ''"
                :footer-props="{
                    itemsPerPageOptions: [10, 20, 50, -1],
                    itemsPerPageText: 'Agrupadores por página:'
                }"
                data-cy="tabelaAgrupadores"
            >
                <template slot="item.action" slot-scope="props">
                    <v-btn
                        elevation="0"
                        @click="exibirDetalhe(props.item)"
                        :class="!isExtraSmall ? '' : 'mb-2 mr-3'"
                        class="grey white--text"
                        :loading="loadingDialog"
                        :data-cy="`editarAgrupador${props.index}`"
                    >
                        <v-icon
                            medium
                            class="mr-2"
                            @click="exibirDetalhe(props.item)"
                            :style="{ color: 'white' }"
                            >edit</v-icon
                        >Editar
                    </v-btn>
                </template>
            </v-data-table>
        </div>
        <Snackbar
            v-if="mostrarSnackbar"
            :mostrarSnackbar="mostrarSnackbar"
            :corSnackbar="corSnackbar"
            :mensagemSnackbar="mensagemSnackbar"
            @fecharSnackbar="mostrarSnackbar = false"
        />        

        <ModalAgrupador
            v-if="dialogModalAgrupador"
            :dialogModalAgrupador="dialogModalAgrupador"
            :agrupador="agrupador"
            @fecharModal="dialogModalAgrupador = false"
            @atualizarListagem="atualizarListagem($event)"
        />
    </div>
</template>

<style></style>

<script>
import ModalAgrupador from '@/components/ModalAgrupador';
import { listarAgrupadores } from '@/helpers/Utilitarios';

export default {
    components: {
        ModalAgrupador
    },
    data() {
        return {
            mostrarSnackbar: false,
            mensagemSnackbar: '',
            corSnackbar: 'green white--text',
            isExtraSmall: false,
            loadingInicial: false,
            loadingDialog: false,
            dialogModalAgrupador: false,
            agrupador: {},
            agrupadores: [],
            colunasAgrupadores: [
                {
                    text: 'ID',
                    sortable: false,
                    value: 'id_agrupador'
                },
                {
                    text: 'Nome Agrupador',
                    sortable: false,
                    value: 'nm_agrupador',
                    align: 'center'
                },
                {
                    text: 'Código Agrupador',
                    sortable: false,
                    value: 'ds_agrupador',
                    align: 'center'
                },
                {
                    text: ' ',
                    sortable: false,
                    value: 'action',
                    align: 'center',
                    width: '200px'
                }
            ]
        };
    },
    async mounted() {
        await this.checarAgrupadores();
    },
    methods: {
        listarAgrupadores,
        async atualizarListagem(mensagemResponse) {
            this.dialogModalAgrupador = false;

            this.exibirSnackbar('green white--text', mensagemResponse);
            await this.checarAgrupadores();
        },
        exibirSnackbar(corSnackbar, mensagemSnackbar) {
            this.mostrarSnackbar = true;
            this.corSnackbar = corSnackbar;
            this.mensagemSnackbar = mensagemSnackbar;
        },
        async checarAgrupadores() {
            try {
                this.loadingInicial = true;
                this.agrupadores = await this.listarAgrupadores(this.$store);
            } catch (e) {
                const mensagemResponse = e?.data?.mensagem
                    ? e?.data?.mensagem
                    : 'Erro ao listar Agrupadores';
                this.exibirSnackbar('red white--text', mensagemResponse);
            } finally {
                this.loadingInicial = false;
            }
        },
        criarAgrupador() {
            this.agrupador = {};
            this.dialogModalAgrupador = true;
        },
        exibirDetalhe(agrupador) {
            this.agrupador = agrupador;
            this.dialogModalAgrupador = true;
        }
    }
};
</script>
