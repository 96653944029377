<template>
    <v-dialog
        v-model="dialogModalAgrupador"
        max-width="600px"
        persistent
        scrollable
        color="background"
        transition="dialog-bottom-transition"
    >
        <v-card>
            <v-card-title class="pa-0">
                <v-toolbar flat dark color="primary">
                    <v-btn
                        icon
                        dark
                        @click="fecharModal()"
                        :disabled="loadingSalvar"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>{{ tituloModal }}</v-toolbar-title>
                </v-toolbar>
            </v-card-title>
            <v-divider></v-divider>
            <div class="pa-3">
                <div class="d-flex align-center justify-space-between pr-4">
                    <div>
                        <v-icon large class="mx-3">
                            mdi-chevron-down
                        </v-icon>
                        {{
                            tipoEdicao === 'CRIAR'
                                ? 'Informe o Nome de identificação do Agrupador'
                                : 'Atualize o Nome de identificação do Agrupador'
                        }}
                    </div>
                    <v-tooltip top>
                        <template
                            v-slot:activator="{
                                on,
                                attrs
                            }"
                        >
                            <v-btn class="ml-2" fab small elevation="0">
                                <v-icon color="grey" v-bind="attrs" v-on="on">
                                    mdi-information-outline
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>
                            {{
                                tipoEdicao === 'CRIAR'
                                    ? 'O Agrupador será utilizado para setorizar as consultas de Títulos que possuírem este marcador. Ao submeter um nome de identificação, nosso sistema irá gerar um códigode retorno'
                                    : 'Só é permitido atualizar o Nome de Identificação do agrupador, as demais informações são fixas e não podem ser alteradas.'
                            }}</span
                        >
                    </v-tooltip>
                </div>
                <div>
                    <v-row v-if="tipoEdicao === 'EDITAR'" class="pa-0 ma-0">
                        <v-col
                            cols="12"
                            xs="12"
                            sm="12"
                            md="6"
                            lg="6"
                            class="pr-1"
                        >
                            <v-text-field
                                class="py-0 px-1"
                                disabled
                                outlined
                                :prepend-icon="
                                    !isExtraSmall ? 'mdi-account-circle' : ''
                                "
                                name="ID Agrupador"
                                label="ID Agrupador"
                                type="text"
                                v-model="agrupador.id_agrupador"
                                data-cy="agrupadorId"
                                hide-details="auto"
                            />
                        </v-col>
                        <v-col
                            cols="12"
                            xs="12"
                            sm="12"
                            md="6"
                            lg="6"
                            class="pl-1"
                        >
                            <v-text-field
                                class="py-0 px-1"
                                disabled
                                outlined
                                :prepend-icon="
                                    !isExtraSmall ? 'mdi-account-circle' : ''
                                "
                                name="Código Agrupador"
                                label="Código Agrupador"
                                type="text"
                                v-model="agrupador.ds_agrupador"
                                data-cy="agrupadorCodigo"
                                hide-details="auto"
                            />
                        </v-col>
                    </v-row>
                    <v-form ref="form" v-model="formularioValido">
                        <v-text-field
                            class="pt-4 px-4 pb-2"
                            outlined
                            :prepend-icon="
                                !isExtraSmall ? 'mdi-account-circle' : ''
                            "
                            name="Nome Agrupador"
                            label="Nome Agrupador"
                            type="text"
                            counter
                            maxlength="100"
                            hint="Tamanho máximo 100 caracteres"
                            v-model="agrupador.nm_agrupador"
                            required
                            :rules="regrasNomeAgrupador"
                            data-cy="agrupadorNome"
                        ></v-text-field>
                    </v-form>
                </div>
            </div>
            <v-divider></v-divider>
            <v-card-actions
                :style="
                    !isExtraSmall
                        ? 'display: flex; justify-content: end'
                        : 'display: flex; justify-content: center'
                "
            >
                <v-btn
                    :disabled="!formularioValido"
                    class="ml-5"
                    :width="isExtraSmall ? '100' : '120'"
                    color="green white--text"
                    @click="salvarAgrupador"
                    :loading="loadingSalvar"
                    data-cy="salvarAgrupador"
                >
                    Salvar
                </v-btn>
                <v-btn
                    class="ml-5"
                    dense
                    color="error"
                    :width="isExtraSmall ? '100' : '120'"
                    :loading="loadingSalvar"
                    @click="fecharModal()"
                >
                    Cancelar
                </v-btn>
            </v-card-actions>
        </v-card>
        <Snackbar
            v-if="mostrarSnackbar"
            :mostrarSnackbar="mostrarSnackbar"
            :corSnackbar="corSnackbar"
            :mensagemSnackbar="mensagemSnackbar"
            @fecharSnackbar="mostrarSnackbar = false"
        />        
    </v-dialog>
</template>

<script>
import AgrupadorService from '@/services/AgrupadorService';
import { eventFecharModal } from '../helpers/Utilitarios';

export default {
    name: 'ModalAgrupador',
    props: {
        dialogModalAgrupador: {
            type: Boolean,
            required: true
        },
        agrupador: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            agrupadorService: new AgrupadorService(this.$store),
            isExtraSmall: false,
            loadingSalvar: false,
            formularioValido: false,
            mostrarSnackbar: false,
            mensagemSnackbar: '',
            corSnackbar: 'green white--text',
            regrasNomeAgrupador: [v => !!v || 'Nome é requerido']
        };
    },
    beforeDestroy() {
        document.removeEventListener('keyup', this.eventFecharModal);
    },
    mounted() {
        document.addEventListener('keyup', this.eventFecharModal);

        this.handleBreakpoints(this.breakpoint);
    },
    methods: {
        eventFecharModal,
        fecharModal(mensagemResponse = null) {
            if (mensagemResponse) {
                this.$emit('atualizarListagem', mensagemResponse);
                return;
            }

            this.$emit('fecharModal');
        },
        async salvarAgrupador() {
            try {
                this.loadingSalvar = true;
                document.removeEventListener('keyup', this.eventFecharModal);
                let mensagemResponse;

                if (this.tipoEdicao === 'CRIAR') {
                    mensagemResponse = await this.agrupadorService.criarAgrupador(
                        this.agrupador
                    );
                }
                if (this.tipoEdicao === 'EDITAR') {
                    mensagemResponse = await this.agrupadorService.atualizarAgrupador(
                        this.agrupador
                    );
                }

                this.fecharModal(mensagemResponse);
            } catch (e) {
                const mensagem = e?.data?.mensagem
                    ? e.data.mensagem
                    : 'Erro ao criar Agrupador';
                this.exibirSnackbar('red white--text', mensagem);
                document.addEventListener('keyup', this.eventFecharModal);
            } finally {
                this.loadingSalvar = false;
            }
        },
        exibirSnackbar(corSnackbar, mensagemSnackbar) {
            this.mostrarSnackbar = true;
            this.corSnackbar = corSnackbar;
            this.mensagemSnackbar = mensagemSnackbar;
        },
        handleBreakpoints(val) {
            this.isExtraSmall = false;
            if (val.xs) {
                this.isExtraSmall = true;
            }
        }
    },
    computed: {
        breakpoint() {
            return this.$vuetify.breakpoint;
        },
        tipoEdicao() {
            if (this.agrupador?.id_agrupador) {
                return 'EDITAR';
            }
            return 'CRIAR';
        },
        tituloModal() {
            if (this.agrupador?.id_agrupador) {
                return 'Editar Agrupador';
            }
            return 'Criar Agrupador';
        }
    },
    watch: {
        breakpoint: {
            deep: true,
            handler(val) {
                this.handleBreakpoints(val);
            }
        }
    }
};
</script>

<style></style>
